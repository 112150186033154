import { Container, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import useLocales from '#/hooks/useLocales';
import CreateUserForm from '#/components/pages/AdminConsulting/create-user/create-user-form';

export default function CreateUser() {
  const { translate } = useLocales();

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack direction="column" spacing={2} width={1}>
          <Stack
            spacing={3}
            sx={{
              textAlign: 'center',
              mx: {
                xs: 0,
                md: 10,
              },
            }}
          >
            <Typography variant="h2">
              {String(translate('global.consulting.dashCardInfos.createUser'))}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {String(
                translate(
                  'global.consulting.dashCardInfos.createUserDescription'
                )
              )}
            </Typography>
          </Stack>
          <CreateUserForm />
        </Stack>
      </Box>
    </Container>
  );
}
