import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useLocales from '#/hooks/useLocales';
import Page from '#/components/shared/ui/Page';
import CreateUser from '#/components/pages/AdminConsulting/CreateUser';

export default function CreateUserPage() {
  const { translate } = useLocales();
  const path = useLocation();
  const navigate = useNavigate();

  const pathName = path.pathname;

  const isInvalidPath =
    !pathName.includes('/patientenverfuegung') &&
    !pathName.includes('/vorsorgeauftrag') &&
    !pathName.includes('/testament');

  useEffect(() => {
    if (isInvalidPath) {
      navigate('/404');
    }
    // eslint-disable-next-line
  }, [isInvalidPath]);

  return (
    <Page title={String(translate('global.consulting.pageTitle'))}>
      <CreateUser />
    </Page>
  );
}
