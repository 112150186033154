import { useForm } from 'react-hook-form';
import {
  Card,
  Typography,
  Stack,
  MenuItem,
  Divider,
  Button,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import PhoneInput from '#/components/shared/hook-form/PhoneInput';
import { ICreateUser } from '#/types/partner';
import { useCreateUser } from '#/api/partnerQueries';

const genders = [
  {
    value: 'M',
    label: 'male',
  },
  {
    value: 'F',
    label: 'female',
  },
];

export default function CreateUserForm() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const pathname = useLocation();

  const { mutateAsync: createUser, isLoading } = useCreateUser();

  const CreateUserSchema = Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    phone: Yup.string().when('first_name', {
      is: (val: string) => val.length > 0,
      then: (schema) =>
        schema
          .required('validations.user.mobile_phone.required')
          .max(22, 'validations.user.mobile_phone.max')
          .matches(/^[0-9\s+]+$/, 'validations.user.mobile_phone.matches')
          .min(7, 'validations.user.mobile_phone.min'),
    }),
    email: Yup.string()
      .email('Invalid email')
      .required('validations.user.email'),
    gender: Yup.string().required('validations.user.gender'),
    language: Yup.string().optional(),
    service: Yup.string().optional(),
  });

  const defaultValues = useMemo(
    () => ({
      first_name: '',
      last_name: '',
      gender: '',
      email: '',
      phone: '',
      language: 'de',
      service: pathname.pathname.split('/').pop(),
    }),
    [pathname]
  );

  const methods = useForm<ICreateUser>({
    resolver: yupResolver(CreateUserSchema as any),
    defaultValues,
  });
  const { handleSubmit, setValue, watch, reset } = methods;

  const handlePhoneChange = (
    value: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // @ts-expect-error
    setValue('phone', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const onSubmit = async (data: any) => {
    await createUser(data)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.createUser'))
        );
        reset(defaultValues);
        handleBack();
      })
      .catch(() => {
        toast.error(String(translate('toast_notifications.error.createUser')));
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ px: 3, py: 2, my: 5 }}>
        <Typography variant="body2">
          {String(translate('global.consulting.createUserForm.description'))}
        </Typography>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
          sx={{ mt: 3 }}
        >
          <RHFTextField
            name="first_name"
            label={String(translate('global.formLabels.firstName'))}
          />
          <RHFTextField
            name="last_name"
            label={String(translate('global.formLabels.lastName'))}
          />
          <RHFSelect name="gender" label={String(translate('global.gender'))}>
            {genders.map((g, i) => (
              <MenuItem key={i} value={g.value}>
                {String(translate(`global.${g.label}`))}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
          sx={{ mt: 3 }}
        >
          <RHFTextField
            name="email"
            label={String(translate('global.formLabels.emailAddress'))}
          />
          <PhoneInput
            name="phone"
            value={watch('phone')}
            onChange={(e) => handlePhoneChange(e)}
            sx={{ width: '100%' }}
            label={String(translate('global.formLabels.mobilePhone'))}
          />
        </Stack>
        <Divider sx={{ my: 3 }} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" onClick={handleBack}>
            <Typography>{String(translate('global.cancel'))}</Typography>
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            <Typography>
              {String(translate('global.formLabels.submit'))}
            </Typography>
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
